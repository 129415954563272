<template>
  <div class="yunvideo">
    <div class="tives"
         v-if="start">
      <!-- nav -->
      <div class="wrap">
        <div class="nav w">
          <ul>
            <li>
              <a @click="topClick(1)"
                 :class="[1==isimg?'active li':'li']"
                 href="javascript:;">{{$t('live.tour')}}</a>
            </li>
            <li>
              <a @click="topClick(2)"
                 :class="[2==isimg?'active li':'li']"
                 href="javascript:;">{{$t('live.tea')}}</a>
            </li>
            <li>
              <a @click="topClick(3)"
                 :class="[3==isimg?'active li':'li']"
                 href="javascript:;">{{$t('live.shows')}}</a>
            </li>
          </ul>
        </div>
      </div>
      <!-- 图文 -->
      <div class="image mb60 w clearfix"
           v-if="liveList.length">
        <div class="image-content"
             @click="Img(item.id,item)"
             v-for="(item,i) in liveList"
             :key="i">
          <div class="kuai">
            <p>{{item.play_status_text}}</p>
          </div>
          <img style="width:285px;height:180px"
               :src="item.image"
               alt />
          <h5>{{item.title}}</h5>
          <i>{{item.time}}</i>
          <span></span>
          <p class="lastP">{{$t('live.pTime')}}：{{item.play_num}}</p>
        </div>
      </div>
      <empty v-else />
      <!-- 分页 -->
      <el-pagination v-if="total!==0"
                     background
                     class="pageAction"
                     layout="prev, pager, next"
                     @current-change="handleCurrentChange"
                     :page-size="pageSize"
                     :current-page="indexPage"
                     :total="total"></el-pagination>
    </div>
    <div v-if="start==false"
         class="tiveKive"
         v-html="contentTive"></div>
  </div>
</template>

<script>
import { getLiveList, getCloudNoticeListData } from "@/api/index";
import empty from "@/components/emptyBox/index";
export default {
  components: {
    empty
  },
  data () {
    return {
      isimg: 1,
      page: 1,
      type: 1, //默认逛展导览
      lang: "zh-cn",
      liveList: [],
      start: true,

      total: 0,
      indexPage: 1,
      pageSize: 12,
      contentTive: ""
    };
  },
  watch: {
    $route: {
      handler () {
        if (this.$route.name == 'Zhibo') {
          sessionStorage.setItem("important_bId_custom", 5);
          this.$store.commit('setBannerId', 5);
          this.isimg = Number(this.$route.query.type) || 1;
          this.isimg = Number(this.isimg) > 3 ? 1 : this.isimg;
          this.getList(this.page, this.type, null);
          this.topClick(this.isimg);
          if (this.$route.query.type == 4) {
            this.$set(this, 'start', false)
            this.tiveCotent()
          } else {
            this.$set(this, 'start', true)
          }
        }
      },
      deep: true
    }
  },
  mounted () {
    this.isimg = Number(this.$route.query.type) || 1;
    this.isimg = Number(this.isimg) > 3 ? 1 : this.isimg;
    this.$nextTick(() => {
      this.topClick(this.isimg);
    });

  },
  methods: {
    tiveCotent () {
      let str = "";
      if (this.$route.query.type == "4") {
        str = "zbgw";
      }
      getCloudNoticeListData({ key: str }).then(res => {
        this.contentTive = res.data.value;
      });
    },
    handleCurrentChange (val) {
      this.indexPage = val;
      this.getList(val, null, "on");
    },
    Img (id, row) {
      if (row.play_status === 1 && row.play_url === "") {
        this.$message({
          message: "预告中，暂无信息...",
          type: "warning",
          offset: 300
        });
        return false;
      } else {
        this.$router.push("/zhiboone?id=" + id);
      }
    },
    topClick (obj) {
      this.isimg = obj;
      this.getList(this.page, obj, "on");
    },
    getList (page, type, on) {
      // console.log('getList', page, type, on, this.isimg);
      getLiveList({
        page: this.indexPage,
        type: on === "on" ? this.isimg : Number(this.$route.query.type)
      }).then(res => {
        if (String(res.code) === "200") {
          this.total = res.data.total;
          this.indexPage = res.data.current_page;
          this.liveList = res.data.liveList;
        }
      });
    }
  },
  created () {
    if (this.$route.query.type == "4") {
      this.$set(this, 'start', false)
      this.tiveCotent();
    } else {
      this.$set(this, 'start', true)
      this.getList(this.page, this.type, null);
    }
    sessionStorage.setItem("important_bId_custom", 5);
    this.$store.commit('setBannerId', 5);
  }
};
</script>

<style scoped  lang="scss">
.tiveKive {
  width: 70%;
  margin: 0 auto;
  padding: 55px;
  line-height: 30px;
}
.pageAction {
  padding: 40px;
  text-align: center;
  ::v-deep {
    .active {
      background-color: #0e9b32 !important;
    }
    li {
      &:hover {
        color: #27ea58 !important;
      }
    }
  }
}
.lastP {
  padding: 18px 0 0 10px;
}
.wrap {
  // background: #F5F5F5;
  height: 100px;
  margin-bottom: 60px;

  .nav {
    ul li {
      float: left;
      position: relative;
      .li {
        position: relative;
        display: inline-block;
        text-decoration: none;
        font-size: 26px;
        color: #4c4c4c;
        height: 100px;
        line-height: 100px;
        margin-right: 80px;
      }

      .active {
        color: #0ba539;

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 4px;
          background-color: #0ba539;
          display: inline-block;
        }
      }
    }
  }
}

.image {
  // height: 1000px;
  // background: burlywoo

  .image-content {
    float: left;
    width: 285px;
    height: 300px;
    // background: cadetblue;
    position: relative;
    float: left;
    margin-right: 20px;
    margin-bottom: 40px;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.5s;
    box-shadow: 2px 6px 14px -4px #cfcfcf;

    &:nth-child(4n) {
      margin-right: 0px;
    }
    &:hover {
      transition: all 0.5s;
      img {
        transform: scale(1.1);
        transition: all 0.5s;
      }
    }

    .kuai {
      width: 70px;
      height: 30px;
      background: rgba(3, 152, 18, 0.9);
      opacity: 0.5;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 2;

      p {
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
      }
    }

    h5 {
      display: block;
      font-weight: 600;
      color: #3a3a3a;
      padding-left: 10px;
      box-sizing: border-box;
      padding-top: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    i {
      display: inline-block;
      padding-left: 10px;
      padding-top: 15px;
      box-sizing: border-box;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
    }
    span {
      display: inline-block;
      padding-left: 15px;
      box-sizing: border-box;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      padding-top: 10px;
    }
    p {
      box-sizing: border-box;
      font-size: 14px;
      font-weight: 400;
      line-height: 30px;
      color: #666666;
    }
  }
}

.swiper-container {
  height: 500px;
  width: 100%;

  .swiper-wrapper {
    .swiper-slide {
      width: 100%;
      height: 100%;
      // background-color: #42b983;
      text-align: center;
      line-height: 500px;
      height: 100%;
      width: 100%;
      background: url(../assets/images/banner4.jpg) no-repeat center top;
      background-size: 1920px 500px;
    }
  }
}
.mb60 {
  margin-bottom: 60px;
}
</style>