<template>
  <div class="empty">
    <el-image class="img" :src="src" :fit="fit"></el-image>
    <div class="tip" v-if="tip">{{tip}}</div>
  </div>
</template>

<script>
import img from './empty.png'
export default {
  name:'emptyBox',
  props: {
    fit: {
      type: String,
      default: 'contain'
    },
    src: {
      type: String,
      default: img
    },
    tip: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .empty{
    padding: 80px 10px;
    display: flex;
    flex-wrap: wrap;
    // align-items: center;
    justify-content: center;
    .tip{
      width: 100%;
      margin-top: 30px;
      font-size: 20px;
      color: #ccc;
      text-align: center;
    }
  }
</style>